:root {
  --translucent-color: rgba(255, 255, 255, 0.705);
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0);
  
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: white;
}

/* ------------------------ landing section styling ---------------------------*/
@keyframes fadeInFromAbove {
  from {color: rgba(255, 255, 255, 0); transform: translateY(-100%);}
  to {color: rgb(255, 255, 255); transform: translateY(0%);}
}

@keyframes fadeInFromBelow {
  from {color: rgba(255, 255, 255, 0); transform: translateY(100%);}
  to {color: rgb(255, 255, 255); transform: translateY(0%);}
}

.landing {
  height: 100vh; /*CHANGE THIS*/
  /* width: 99vw;  CHANGE THIS TOO */
  display: grid;
  grid-template-rows: 2fr 1fr;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://wallpapercave.com/wp/wp5398432.jpg);  */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/SunnySlope.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0);
}

.landing-message {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

#hey {
  font-size: 250px;
  margin-right: 50px;
  animation-name: fadeInFromAbove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#im-chris {   /*This is not being used currently*/
  font-size: 150px;
  animation-name: fadeInFromAbove;
  animation-delay: 1.1s;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.sub-landing-message {
  animation-name: fadeInFromBelow;
  /* animation-delay: 2.2s; */
  animation-delay: 1s;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

/* ============Home page Landing Styling Change for Small Screens============= */
@media only screen and (max-width: 1217px){
  #hey {
    font-size: 160px;
  }

  .landing {
    background-size: auto 100vh;
  }

  .sub-landing-message {
    font-size: 20px;
  }
}
/* ================End small screen change===================================== */

/* ----------------------------------------------------------------------------- */


/* -------------------- Sidebar Styling -------------------------------------*/
@keyframes comeFromLeft {
  from {transform: translateX(-120%); background:rgba(255, 255, 255, 0);}
  to {transform: translateX(0%); background: var(--translucent-color);}
}

.sidebar {
  /* grid-area: sidebar; */
  /* background: var(--translucent-color); */
  background:rgba(255, 255, 255, 0);
  display: grid;
  grid-template-rows: 0.5fr 1fr 1.5fr;
  grid-template-areas: 
  "name"
  "nav"
  "portrait";
  margin: 7px;
  border-radius: 10px;

  /* height: 99vh; This makes the bottom margin not right but solves issue of resizing height */

  position: -webkit-sticky;
  position: sticky;
  top: 7px;

  transform: translateX(-120%);
  animation-name: comeFromLeft;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  height: 98vh;
}

.nav {
  grid-area: nav;
  margin: 20px 20px 20px 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-top: solid black 1px;
  border-bottom: solid black 1px;
}

.nav > ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.nav > ul > li {
  padding: 7px;
  color: black;
  margin:3px;
}

.nav > ul> li > a {
  color: black;
  padding: 10px 120px 10px 120px;
  text-decoration: none;
}

.nav > ul> li > a:hover {
  cursor: pointer;
}

.nav > ul > li:hover {
  background-color: rgba(255, 255, 255, 0.4001);
}

.name {
  grid-area: name;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
  font-size: 40px;
  color: rgb(0, 0, 0);
}

#subname {
  font-size: 25px;
}

/* .portrait-container{
  grid-area: portrait;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.portrait {
  grid-area: portrait;
  border-radius: 10px;
  margin: 0px 20px 20px 20px;
  background-image: url("./Images/CKim_Water.jpg");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}

/* For top of page for non-home */
.sidebar-horizontal {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  grid-template-areas: "name-h portrait-h nav-h"; 
  /* align-items: center;
  justify-content: space-around; */
  /* width: 99vw; */
  /* margin: auto; */
  background-color: rgba(255, 255, 255, 0),var(--translucent-color);
  margin: 0px 10px 10px 10px;
  padding: 7px;
  border-radius: 10px;
}

.nav-horizontal {
  grid-area: nav-h;

  /* border: 1px solid black; */

  margin: 5px 20px 5px 0px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px; 
}

.nav-horizontal > ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.nav-horizontal > ul > li {
  padding: 7px 7px 7px 7px;
  margin: 3px;
}

.nav-horizontal > ul > li > a {
  color: #fff;
  padding: 7px 10px 7px 10px;
  text-decoration: none;
}

.nav-horizontal > ul > li > a:hover {
  cursor: pointer;
}

.nav-horizontal > ul > li:hover {
  background-color: rgba(100, 96, 96, 0.4);
  border-radius: 10px;
}

.name-horizontal {
  grid-area: name-h;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 10px 10px;
  margin: 5px 0px 5px 0px;
  font-size: 25px;
  color: rgb(255, 255, 255);
}

/* #subname {
  font-size: 25px;
} */

.portrait-horizontal {
  grid-area: portrait-h;
  width: 70px;
  height: 70px;
  border-radius: 20px;
  margin: 20px 20px 15px 20px;
  background-image: url("./Images/CKim_Water.jpg");
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
}
/* ---------------------------------------------------------------------------- */


/* ---------Home (below landing ) styling--------- */
.home {
  display: grid;
  grid-template-columns: 1fr auto;
  /* grid-template-rows: 1fr auto; */
  grid-template-areas:
  "blank main"
  "blank main";
  height: auto;
  /* width: 99vw; */
  background: rgb(218, 234, 255);
  text-align: center;
  font-size: 12px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/SunnySlope.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}


/* =================BACKGROUND FOR SMALL SCREEN================= */
@media only screen and (max-width: 1217px){
  .home{
    background-size: auto 100vh;
}
}

.home-main-content {
  grid-area: main;
  border-radius: 10px;
  margin: 7px;
  margin-left: 0;
  /* background: rgba(255, 255, 255, 0.548); */
  display: grid;
  grid-template-columns: 1fr 8fr;
  font-size: 30px;
}

.section-header {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  transform-origin: center;
  font-size: 100px;
  margin-top: 60px;
  margin-bottom: 60px;;
  /* border-left: 1px solid black; */
  letter-spacing: 7px;
  color: white;
}

/* Home page About section */
.about-sec-container {
  background-color: var(--translucent-color);
  margin: 0 0 0 1rem;
  padding: 3rem;
  border-radius: 10px;
}
.about-sec-text {
  display: flex;
  flex-direction: column;
  align-items:center;
}
.about-sec-link-icons{
  margin-top: 40px;
  display:flex;
  justify-content: space-around;
  width: 60%;
}
.about-sec-link-icons > a > i{
  color: black;
}


/* Home page projects section */
.projects-sec-text {
  background-color: var(--translucent-color);
  margin: 1rem 0 1rem 1rem;
  padding: 3rem;
  border-radius: 10px;
}
.home-projects-photos {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
}
.first-project-photo-home{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.first-project-photo-home > img{
  max-width: 100%;
  width:30%;
  height:auto;
}
.home-projects-photos > img {
  max-width: 100%;
  width: 40%;
  height: auto;
}

/* Photo Section not being used currently */
.photo-sec-container {
  background-color: rgba(255, 255, 255, 0.096);
  margin: 0 0 0 1rem;
  padding: 2rem;
  border-radius: 10px;
}
.photo-sec-text {
  /* background-color:#ecececef; */
  background-color: var(--translucent-color);
  margin: 0 0 0 1rem;
  padding: 2rem;
  border-radius: 10px;
}

.photo-sec-image{
  background-image: url("./Images/MistSlope.jpg");
  margin: 30px 0 0 1rem;
  border-radius: 30px;
  height: 500px;
  background-size: 100vw auto;
  background-position: center center;
}
/* ---------------------------------------------------------------------------------------- */

/* -----------------------------------About page styling---------------------------------- */
.about-page-container {
  /* display: grid; */
  /* grid-template-columns: 1fr 4fr; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./Images/BeebeThroughTrees.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;

  /* height: 100vh; */
}

.about-page-text {
  color: white;
  margin: 20px;
  margin-bottom: 200px;
  padding: 20px;
  font-size: 25px;
}
.about-page-text > p{
margin: 20px;
}
/* ---------------------------------------------------------------------------------------- */


/* ---------------------------------Projects page styling------------------------------ */
.projects-page-container{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./Images/Beebe.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
}

.projects-page-container > p{
  color: white;
}

.project-descriptions {
  display: flex;
  flex-direction: column; 
  align-items: center;
  font-size: 25px;
}

.project-description-container {
  background-color: var(--translucent-color);
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 60vw;

}

.project-description-container > img {
  width: 40%;
  height: auto;
  margin: 10px;
}

.project-name-and-lang-icon{
  display: grid;
  grid-template-columns: 1fr auto;
}


/* --------------------------------------------------------------------------------- */


/* ---------------------------------Photography page styling------------------------- */
.photo-page-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/MistSlope.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;

  /* height: 100vh; */
}

#photo-header {
  margin-top: 200px;
  margin-bottom: 400px;
  font-size: 200px;
}


.test-photo-container {
  /* display: flex;
  overflow: scroll; */
  white-space: nowrap;
  overflow-x: auto;
}

.photo-item {
  display: inline-block;
  height: 600px;
  width: 800px;
  background-color: white;
  background-size: 800px auto;
  background-position: center center;
  margin: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 20px;
  z-index: 1;
}

#sar {
  background-image: url("./Images/Photography/Sarra.JPG");
}

#nep {
  background-image: url("./Images/Photography/NepVen.JPG");
}

#feather {
  background-image: url("./Images/Photography/BranchFeather.JPG");
}

#clouds {
  background-image: url("./Images/Photography/Clouds.JPG");
}

#moon {
  background-image: url("./Images/Photography/DayMoon.JPG");
}

#botanic {
  background-image: url("./Images/Photography/BotanicGarden.JPG");
}

#dionaea {
  background-image: url("./Images/Photography/Dionaea.JPG");
}

#goldenburman {
  background-image: url("./Images/Photography/GoldenBurmannii.JPG");
}

#burmancover {
  background-image: url("./Images/Photography/BurmanniiCover.JPG");
}

#raintree {
  background-image: url("./Images/Photography/RainTree.JPG");
}
/* .sar:hover, .nep:hover, .feather:hover, .moon:hover, .clouds:hover {
  transform: scale(1.2, 1.2);
} */
/* ------------------------------------------------------------------------------------------- */


/* ---------------------------------Contact page styling------------------------- */
.contact-page-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./Images/FallLeaves.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
  /* height: 100vh; */
}

.contact-page-text-container {
  color: white;
  margin: 50px;
  margin-bottom: 275px;
}
#contact-email-line {
  font-size: 35px;
  margin: 50px;
}
#email {
  font-weight: bold;
  font-size: 45px;
}

/* Contact Form (NOT being used) */
.contact-form-container {
  width: 45vw;
  margin: 40px auto 40px auto;
  background-color: var(--translucent-color);
  padding: 20px;
  border-radius: 15px;
}
.contact-form {
  display: flex;
  flex-direction: column;
}
.contact-form > label, input, textarea {
  text-align: left;
  margin: 14px;
  background-color: transparent;
  border: none;
}
.contact-name, .contact-email {
  
}
.contact-form > input, textarea {
  border-bottom: solid 1px black;
}
.contact-form > label {
  margin-bottom: 0;
  font-weight: bold;
}
.comments{

}
.submit-btn {
  background-color: white;
  color: black;
  width: 80px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: none;
}
.submit-btn:hover {
  cursor: pointer;
  background-color: gray;
}
/* ---------------------------------------------------------------------------------------- */


/* ---------------------------------Resume page styling------------------------- */
.resume-page-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./Images/Memorial.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;

  /* height: 100vh; */

}

.resume-main-content {
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
}

.resume-title-and-btn {
  /* display: flex; */
  /* flex-direction: column; */
}

.page-header-resume {
  color: white;
  font-size: 120px;
  margin-bottom: 100px;
  margin-left: 40px;
  margin-top: 50px;
}

.resume-display {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.resume-btn{
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

.resume-btn:hover {
  cursor: pointer;
  background-color: rgb(216, 216, 216);
}
/* ----------------------------------------------------------------------------------------------- */


/* ----------------------------------Page header text styling---------------------------- */
.page-header {
  color: white;
  font-size: 100px;
}
/* -------------------------------------------------------------------------------- */

/* ----------------------------------Spacer background styling ----------------------------------------*/
.home-spacer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/SunnySlope.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
}
/* ------------------------------------------------------------------------------------------- */

/* -----------------------------Footer styling--------------------------------------- */
.footer {
  background-color: var(--translucent-color);
  margin: 20px 7px 30px 7px;
  padding: 50px;
  flex-grow: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer > a{
  color: black;
}

.footer >a:hover {
  transform: scale(1.2, 1.2);
}

/* Footer background stylings */
.home-footer-background {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 99vw; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./Images/SunnySlope.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
  padding-top: 40px; /*replaces Spacer*/
}

@media only screen and (max-width: 1217px){
  .home-footer-background{
    background-size: auto 100vh;
}
}

.about-footer-background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("./Images/BeebeThroughTrees.jpg"); 
  background-size: 100vw auto;
  background-position: center center;
  background-attachment: fixed;
}

.subpage-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91vw;
  margin: auto;
  margin-top: 30px;
}


